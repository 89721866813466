import {
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Autocomplete
} from "@mui/material";
import styles from "./style.module.css";
import {ICourseCLO,ICLOSOMapping} from '../../../model/EqaCore';

interface CourseCLOProps{
    currentLang: string,
    courseCLO: ICourseCLO[]
}

interface CLOSOMappingSectionProps{
    currentLang: string,
    cloSoMapping: ICLOSOMapping[]
}

export const SODetailSection: React.FC<CLOSOMappingSectionProps>= ({currentLang, cloSoMapping}) => {
    return (
        <>
            <TableContainer component={Paper} className={styles.tablecontainer}>
                <Table className={styles.customtable}>
                    <TableHead>
                        <TableRow>
                            <TableCell colSpan={4} className={styles.heading}><span>SO Detail</span></TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className={styles.label}><span>SO ID</span></TableCell>
                            <TableCell colSpan={3} className={styles.label}><span>SO DESCRIPTION</span></TableCell>
                           
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {cloSoMapping && cloSoMapping?.filter((item, index, self) =>
                             self.findIndex((t) => t.soId === item.soId) === index )
                        .map((item: any, index: any) => {
                            return (
                                <>
                                    <TableRow>
                                        <TableCell>{item.soId}</TableCell>
                                        <TableCell colSpan={3}>{item.soDescription}</TableCell>
                                       
                                    </TableRow>
                                </>
                            )
                        })}

                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}