interface ScheduleCourseState {
    scheduleCourse: any | null;
    loading: any;
    assessmentByLoggedInUser: any | null;
}

const initialState: ScheduleCourseState = {
    scheduleCourse: null,
    loading: false,
    assessmentByLoggedInUser: null
};

const assessmentReducer = (state = initialState, action: any): ScheduleCourseState => {
    switch (action.type) {

        case 'FETCH_SCHEDULE_COURSE_DATA_SUCCESS':
            return {
                ...state,
                loading: false,
                scheduleCourse: action.payload,
            };
        case 'FETCH_ASSESSMENT_BY_LOGGEDIN_USER_DATA_SUCCESS':
            return {
                ...state,
                loading: false,
                assessmentByLoggedInUser: action.payload
            }
        case 'ADD_ASSESSMENT_DATA':
            return {
                ...state,
                assessmentByLoggedInUser: [...state.assessmentByLoggedInUser, action.payload.data]
            }
        case 'UPDATE_ASSESSMENT_DATA':
            return {
                ...state,
                assessmentByLoggedInUser: state.assessmentByLoggedInUser.map((assessmentByLoggedInUser: any, index: any) =>
                    index === action.payload.index ? { ...assessmentByLoggedInUser, ...action.payload.updatedAssessmentDetails } : assessmentByLoggedInUser
                ),
            };
        case 'DELETE_ASSESSMENT_DATA':
            return {
                ...state,
                assessmentByLoggedInUser: state.assessmentByLoggedInUser.filter((assessment: any, idx: number) => assessment.assessmentId !== action.payload.id),
            };
        case 'CLEAR_ASSESSMENT_DATA':
            return{
                ...state,
                assessmentByLoggedInUser: null
            }
        default:
            return state;
    }
};

export default assessmentReducer;