import {
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Autocomplete,
    Grid,
    Box
} from "@mui/material";
import styles from "./style.module.css";
import {ICourse} from '../../../model/EqaCore';
interface CourseDetailSectionProps{
    currentLang: string,
    windowSize: number[],
    courseData: ICourse,
    syllabusFormat?: string
}


export const CourseDetailSection:React.FC<CourseDetailSectionProps>=({currentLang,windowSize, courseData, syllabusFormat})=>{
    return(
        <>
            {/* Header */}
            <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell style={{ paddingRight: "-20px" }}>
                            {/* <img src={universityLogo}
                            width={120}
                            height={120}
                            alt="avatar"
                            loading="lazy" /> */}

                            <Box sx={{ flexGrow: 1, padding: 0 }}>
                                <Grid container sx={{ alignItems: 'center' }} spacing={
                                    // Categorize according to small, medium, large screen
                                    (windowSize[0] < 576) ? (0) : ((windowSize[0] < 768) ? (1) : ((windowSize[0] < 992) ? (2) : (3)))
                                }>

                                    {/* University Logo */}
                                    <Grid item xl={2} lg={2} md={2} sm={12} xs={12} sx={{ mt: -2 }}>
                                        <img
                                            // className={styles.universityLogo}
                                            width={120}
                                            height={120}
                                            src={require(`../../../../../assets/Images/universityLogo/taifuniversitylogo.jpeg`)}
                                            alt="University Logo"
                                        // style={{ width: '100%', height: 'auto', display: 'block'}}
                                        />
                                    </Grid>

                                    <Grid item xl={8} lg={8} md={8} sm={12} xs={12} sx={{ padding: 0 }}>
                                        <p className={styles.collegeNameHeading}>Taif University</p>
                                        <p className={styles.collegeNameHeading}>College Of Computer and Information Technology</p>

                                    </Grid>
                                    
                                    {syllabusFormat && syllabusFormat === 'ncaaa' && (
                                        <Grid item xl={2} lg={2} md={2} sm={12} xs={12} sx={{ mt: -2 }}>
                                            <img
                                                width={120}
                                                height={90}
                                                src={require(`../../../../../assets/Images/Syllabus/NCAAA.png`)}
                                                alt="NCAAA Logo"
                                            />
                                        </Grid>
                                    )}
                                </Grid>
                            </Box>
                        </TableCell>
                    </TableRow>
                </TableHead>

            </Table>
            </TableContainer>

            <TableContainer component={Paper} className={styles.tablecontainer}>
                <Table className={styles.customtable}>
                    <TableHead>
                        <TableRow>
                            <TableCell colSpan={4} className={styles.heading}><span>Course Specification</span></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell className={styles.label}><span>COURE CODE</span></TableCell>
                            <TableCell>{courseData.courseCode}</TableCell>
                            <TableCell className={styles.label}><span>COURSE NAME</span></TableCell>
                            <TableCell>{courseData.courseName}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className={styles.label}><span>COURSE TYPE</span></TableCell>
                            <TableCell>{courseData.courseTypeName}</TableCell>
                            <TableCell className={styles.label}><span>ELECTIVE REQUIRED</span></TableCell>
                            <TableCell>{courseData.requiredElective}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className={styles.label}><span>COURSE DESCRIPTION</span></TableCell>
                            <TableCell colSpan={3}>{courseData.courseDesc}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className={styles.label}><span>CREDIT HOURS</span></TableCell>
                            <TableCell>{courseData.courseCreditHours}</TableCell>
                            <TableCell className={styles.label}><span>COURSE LEVEL</span></TableCell>
                            <TableCell>{courseData.courseLevel}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className={styles.label}><span>TEACHING HOURS PER WEEK</span></TableCell>
                            <TableCell>{courseData.teachingHoursPerWeek}</TableCell>
                            <TableCell className={styles.label}><span>LAB HOURS PER WEEK</span></TableCell>
                            <TableCell>{courseData.labHoursPerWeek}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className={styles.label}><span>ACADEMIC YEAR OFFERED</span></TableCell>
                            <TableCell>{courseData.academicYearOffered}</TableCell>
                            <TableCell className={styles.label}><span>COURSE PREREQUISITE</span></TableCell>
                            <TableCell>{courseData.coursePrerequisite}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className={styles.label}><span>COURSE COREQUISITE</span></TableCell>
                            <TableCell>{courseData.courseCoRequisite}</TableCell>
                            <TableCell className={styles.label}><span>STUDY PLAN</span></TableCell>
                            <TableCell>{courseData.studyPlan}</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell colSpan={4} className={styles.heading}><span>MODE OF INSTRUCTION</span></TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className={styles.label}><span>TRADITIONAL CLASSROOM</span></TableCell>
                            <TableCell>{courseData.moiTraditionalClassroom}</TableCell>
                            <TableCell className={styles.label}><span>BLENDED</span></TableCell>
                            <TableCell colSpan={3}>{courseData.moiBlended}</TableCell>
                        </TableRow>
                        
                        <TableRow>
                            <TableCell className={styles.label}><span>ELEARNING</span></TableCell>
                            <TableCell>{courseData.moiElearning}</TableCell>
                            <TableCell className={styles.label}><span>CORRESPONDENCE</span></TableCell>
                            <TableCell>{courseData.moiCorrespondence}</TableCell>
                        </TableRow>
                        
                        <TableRow>
                            <TableCell className={styles.label}><span>OTHERS</span></TableCell>
                            <TableCell colSpan={3}>{courseData.moiOthers}</TableCell>
                        </TableRow>


                        <TableRow>
                            <TableCell colSpan={4} className={styles.heading}><span>ACTUAL LEARNING HOURS</span></TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell colSpan={4} className={styles.heading}><span>Contact Hours:</span></TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className={styles.label}><span>LECTURE</span></TableCell>
                            <TableCell>{courseData.alhChLecture}</TableCell>
                            <TableCell className={styles.label}><span>LABORATORY</span></TableCell>
                            <TableCell>{courseData.alhChLaboratory}</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell className={styles.label}><span>TUTORIAL</span></TableCell>
                            <TableCell>{courseData.alhChTutorial}</TableCell>
                            <TableCell className={styles.label}><span>OTHERS</span></TableCell>
                            <TableCell>{courseData.alhChOther}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell colSpan={4} className={styles.heading}><span>Other Learning Hours:</span></TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className={styles.label}><span>STUDY</span></TableCell>
                            <TableCell>{courseData.alhOlhStudy}</TableCell>
                            <TableCell className={styles.label}><span>ASSIGNMENT</span></TableCell>
                            <TableCell>{courseData.alhOlhAssignment}</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell className={styles.label}><span>LIBRARY</span></TableCell>
                            <TableCell>{courseData.alhOlhLibrary}</TableCell>
                            <TableCell className={styles.label}><span>RESEARCH</span></TableCell>
                            <TableCell>{courseData.alhOlhResearch}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className={styles.label}><span>OTHER</span></TableCell>
                            <TableCell colSpan={3}>{courseData.alhOlhOther}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell colSpan={4} className={styles.heading}><span>STUDENT ACADEMIC SUPPORT</span></TableCell>
                        </TableRow>
                        <TableRow>
                        <TableCell colSpan={4}>{courseData.studentAcademicSupport}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell colSpan={4} className={styles.heading}><span>FACILITIES REQUIRED</span></TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className={styles.label}><span>ACCOMODATION</span></TableCell>
                            <TableCell colSpan={3}>{courseData.frAccomodation}</TableCell>
                        </TableRow>
                        
                        <TableRow>
                            <TableCell className={styles.label}><span>TECHNOLOGY RESOURCES</span></TableCell>
                            <TableCell colSpan={3}>{courseData.frTechnologyResources}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className={styles.label}><span>OTHER RESOURCES</span></TableCell>
                            <TableCell colSpan={3}>{courseData.frOtherResources}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell colSpan={4} className={styles.heading}><span>SPECIFICATION APPROVAL DATA</span></TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className={styles.label}><span>SAD COMMITTEE</span></TableCell>
                            <TableCell>{courseData.sadCommittee}</TableCell>
                            <TableCell className={styles.label}><span>SAD DATE</span></TableCell>
                            <TableCell>{courseData.sadDate}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className={styles.label}><span>SAD REFERENCE NO</span></TableCell>
                            <TableCell colSpan={3}>{courseData.sadReferenceNo}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}