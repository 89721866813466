import {
    Box,
    Typography,
    Button,
    Grid,
    TextField,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Autocomplete
} from "@mui/material";
import { useTranslation } from "react-i18next";
import styles from "./style.module.css";
import {ICourse, ICourseObjective, ICourseCLO, IAssessmentMethod, ICourseTopic, ICourseBook, ICLOSOMapping} from '../../../model/EqaCore';
import React from 'react'
import CourseObjective from "../CourseObjective/AddCourseObjective";
import { CourseObjectiveSection } from "./CourseObjectiveSection";
import { CourseCLOSection } from "./CourseCLOSection";
import { AssessmentMethodSection } from "./AssessmentMethodSection";
import { CourseTopicSection } from "./CourseTopicSection";
import { CourseBookSection } from "./CourseBookSection";
import { CLOSOMappingSection } from "./CLOSOMappingSection";
import { CourseDetailSection } from "./CourseDetailSection";
import { SODetailSection } from "./SODetailSection ";


interface NCAAASyllabusProps{
    currentLang: string,
    courseData: ICourse,
    windowSize: number[],
    courseObjective: ICourseObjective[],
    courseCLO: ICourseCLO[],
    assessmentMethod: IAssessmentMethod[],
    courseTopic: ICourseTopic[],
    bookData: ICourseBook[],
    cloSoMapping: ICLOSOMapping[],
}

export const NCAAASyllabusFormat : React.FC<NCAAASyllabusProps>= ({currentLang, windowSize, courseData,courseObjective,courseCLO, assessmentMethod, courseTopic, bookData,cloSoMapping}) => {
    
  return (
        <div id="ncaaa">
            <CourseDetailSection currentLang={currentLang} windowSize={windowSize} courseData={courseData} syllabusFormat="ncaaa" />
            <CourseObjectiveSection currentLang={currentLang} courseObjective={courseObjective} /> 
            <CourseCLOSection currentLang={currentLang} courseCLO={courseCLO} />
            <AssessmentMethodSection currentLang={currentLang} assessmentMethod={assessmentMethod} />
            <CourseTopicSection currentLang={currentLang} courseTopic={courseTopic} />
            <CourseBookSection currentLang={currentLang} courseBook={bookData} />
            <CLOSOMappingSection currentLang={currentLang} cloSoMapping={cloSoMapping} />
            <SODetailSection currentLang={currentLang} cloSoMapping={cloSoMapping} />
        </div>
    )
}
