import {
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Autocomplete
} from "@mui/material";
import styles from "./style.module.css";
import {IAssessmentMethod} from '../../../model/EqaCore';
interface AssessmentMethodProps{
    currentLang: string,
    assessmentMethod: IAssessmentMethod[]
}


export const AssessmentMethodSection:React.FC<AssessmentMethodProps>=({currentLang, assessmentMethod})=>{
    return(
        <>
            <TableContainer component={Paper} className={styles.tablecontainer}>
        <Table className={styles.customtable}>
            <TableHead>
                <TableRow>
                    <TableCell colSpan={6} className={styles.heading}><span>ASSESSMENT METHOD</span></TableCell>
                </TableRow>
                <TableRow>
                <TableCell className={styles.label}><span>METHOD NAME</span></TableCell>
                    <TableCell colSpan={4} className={styles.label}><span>DESCRIPTION</span></TableCell>
                    <TableCell className={styles.label}><span>DISTRIBUTION</span></TableCell>
                    
                </TableRow>
            </TableHead>
            <TableBody>
                {assessmentMethod && assessmentMethod?.map((item: any, index: any) => {
                    return (
                        <>
                            <TableRow>
                                <TableCell>{item.methodName}</TableCell>
                                <TableCell colSpan={4}>{item.description}</TableCell>
                                <TableCell>{item.distribution +" %"}</TableCell>
                                
                            </TableRow>
                        </>
                    )
                })}

            </TableBody>
        </Table>
    </TableContainer>
        </>
    )
}