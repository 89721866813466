import { BASE_URL } from "../../../constants";
import { PORTAL_URL } from "../../../constants";
const readAPI = {
  UserProfilePicture : `${BASE_URL}/IDENTITY-SERVICE/privileges/downloadUserPicture`,
  Users : `${BASE_URL}/IDENTITY-SERVICE/privileges/fetchUsers`,
  Apps: `${BASE_URL}/IDENTITY-SERVICE/privileges/fetchAppDetails`,
  Groups: `${BASE_URL}/IDENTITY-SERVICE/privileges/fetchGroups`,
  Roles : `${BASE_URL}/IDENTITY-SERVICE/privileges/fetchRoles`,
  AppForm: `${BASE_URL}/IDENTITY-SERVICE/privileges/fetchAppForm`,
  RoleApp: `${BASE_URL}/IDENTITY-SERVICE/privileges/fetchPrivileges`,
  GroupRole: `${BASE_URL}/IDENTITY-SERVICE/privileges/fetchGroupRoles`,
  UserGroup: `${BASE_URL}/IDENTITY-SERVICE/privileges/fetchUserGroups`,
  getAllCampusesByCollegeId: `${BASE_URL}/EQACORE-SERVICE/getAllCampusesByCollegeId/`,
  Department: `${BASE_URL}/EQACORE-SERVICE/department`,
  College: `${BASE_URL}/EQACORE-SERVICE/colleges`,
  University: `${BASE_URL}/EQACORE-SERVICE/university`,
  Campus: `${BASE_URL}/EQACORE-SERVICE/campus`,
  Offeredcourse : `${BASE_URL}/EQACORE-SERVICE/schedule/offeredCourses`,
  Program: `${BASE_URL}/EQACORE-SERVICE/programs`,
  Assessments: `${BASE_URL}/EQACORE-SERVICE/assessment/assessments/byLoggedInUser`,
  AssessmentType: `${BASE_URL}/EQACORE-SERVICE/assessment/assessmentTypes`,
  SessionType: `${BASE_URL}/EQACORE-SERVICE/schedule/sessionTypes`,
  getAssessmentByOfferedCourseId: `${BASE_URL}/EQACORE-SERVICE/assessment/assessments`,
  getOverallGradeData: `${BASE_URL}/EQACORE-SERVICE/assessment/fetchOverAllGrades`,
  getSurveyloggedInByUser: `${PORTAL_URL}/eqa-survey-service/api/v1.0/survey/loggedInByUser`,
  Committee: `${BASE_URL}/EQAMOM-SERVICE/mom/committee`,
  Courses: `${BASE_URL}/EQACORE-SERVICE/courses`,
  FetchUsers: `${BASE_URL}/IDENTITY-SERVICE/privileges/fetchUsers`,
  getActiveAcademicYear: `${BASE_URL}/EQACORE-SERVICE/schedule/activeAcademicYear`
};

export default readAPI;
