import * as React from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import Cookies from "js-cookie";
import axios from 'axios';
import SendIcon from '@mui/icons-material/Send';
import { Download, TimesOneMobiledata } from '@mui/icons-material';
import { FaBook } from "react-icons/fa";
// import createAPI from "../../../../data/api/create";
import readAPI from "../../../data/api/read";
import {EqaCoreService}  from "../../../service/EqaCoreService"
// import jsPDF from 'jspdf-autotable';
// import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import html2canvas from 'html2canvas';


// importing from material ui

import { styled, alpha } from '@mui/material/styles';
import Menu, { MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import EditIcon from '@mui/icons-material/Edit';
import Divider from '@mui/material/Divider';
import ArchiveIcon from '@mui/icons-material/Archive';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useSelector } from 'react-redux';
import { RootState } from "../../../../../redux/store";

import {
    Box,
    Typography,
    Button,
    Grid,
    TextField,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Autocomplete
} from "@mui/material";

import SnackBar from "../../../../../Components/SnackBar";
import { useTranslation } from "react-i18next";
import styles from "./style.module.css";
import { TfiThemifyFavicon } from "react-icons/tfi";

import { StandardSyllabus } from "./StandardSyllabus";
import { AbetSyllabusFormat } from "./AbetSyllabusFormat";
import { NCAAASyllabusFormat } from "./NCAAASyllabusFormat";

interface SyllabusProps {
    // openProgramModal: boolean;
    // setOpenProgramModal: React.Dispatch<React.SetStateAction<boolean>>;

    setIsOpen: any,
    isOpen: Boolean,

    // Current Language
    currentLang: string;
    // setCurrentLang: React.Dispatch<React.SetStateAction<string>>;
    courseCodes?: any
}



const StyledMenu = styled((props: MenuProps) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 180,
        color:
            theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px 0',
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            '&:active': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity,
                ),
            },
        },
    },
}));



const Syllabus: React.FC<SyllabusProps> = ({
    // openProgramModal,
    // setOpenProgramModal,

    setIsOpen,
    isOpen,

    // Current Language
    currentLang,
    // setCurrentLang,
    courseCodes
}) => {
    const { t } = useTranslation();

    const navigate = useNavigate();

    const [courseCode, setCourseCode] = useState<string>("");

    const [courseCodeError, setCourseCodeError] = useState<boolean>(false);

    const [courseCodeErrorMessage, setCourseCodeErrorMessage] = useState<string>("")

    const [bookData, setBookData] = useState<any>([]);
    const [courseData, setCourseData] = useState<any>([]);
    const [courseObjectives, setCourseOBjectives] = useState<any>([])
    const [courseClos, setCourseClos] = useState<any>([])
    const [courseTopics, setCourseTopics] = useState<any>([])
    const [cloSoMapping, setCloSoMapping] = useState<any>([])
    const [assessmentMethods, setAssessmentMethods] = useState<any>([])

    const [showMessage, setShowMessage] = useState<boolean>(false)
    const [showErrorMessage, setShowErrorMessage] = useState("")
    const [showErrorMessageForAbet, setShowErrorMessageForAbet] = useState("")
    const [showErrorMessageForNCAAA, setShowErrorMessageForNCAAA] = useState("")
    const [responseData, setResponseData] = useState<any>([]);
    const [assessmentType,setAssessmentType] = useState<any>([]);

    const currentFormatedDate: string = new Date().toLocaleString('en-US', { month: 'long', day: 'numeric', year: 'numeric' });

    ///////////////////////////////// Snackbar State /////////////////////////////////
    const [snackBarHandler, setSnackBarHandler] = useState({
        open: false,
        message: '',
        severity: 'success'
    });
    ///////////////////////////////// Snackbar State /////////////////////////////////

    const [isLoadingSpinnerBasic, setIsLoadingSpinnerBasic] = useState(false);
    const [isLoadingSpinnerNcaaa, setIsLoadingSpinnerNcaaa] = useState(false);
    const [isLoadingSpinnerAbet, setIsLoadingSpinnerAbet] = useState(false);

    const universityLogo = require(`../../../../../assets/Images/universityLogo/taifuniversitylogo.jpeg`);
    const basicImage = require(`../../../../../assets/Images/Syllabus/basic.png`);
    const abetImage = require(`../../../../../assets/Images/Syllabus/ABET.png`);
    const ncaaaImage = require(`../../../../../assets/Images/Syllabus/NCAAA.png`);

    const [selectedOption, setSelectedOption] = useState<any>('');

    const [showAnchorEl, setShowAnchorEl] = useState(null);
    const showOpen = Boolean(showAnchorEl);

    const [downloadAnchorEl, setDownloadAnchorEl] = useState(null);
    const downloadOpen = Boolean(downloadAnchorEl);
    const [courseCodeList, setCourseCodeList] = useState<any>([]);

    // coursecode autocomplete

    const courseCodeListOptions = courseCodeList?.map((courseCode: any) => ({
        label: `${courseCode.courseCode}: ${courseCode.courseName}`,
        value: courseCode.courseCode,
    }));
    const handleCourseCodeChange = (event: any, newValue: any) => {
        setCourseCode(newValue ? newValue.value : null);
    };

    const handleShowClick = (event: any) => {
        setShowAnchorEl(event.currentTarget);
    };

    const handleDownloadClick = (event: any) => {
        setDownloadAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setShowAnchorEl(null);
        setDownloadAnchorEl(null);
    };

    useEffect(() => {
        if(courseCodes !== ""){
            setCourseCode(courseCodes);
        }
    }, [courseCodes])


    //show syllabus table
    // const gettingSyllabusData = () => {
    //     let accessToken: any = Cookies.get("accessToken");
    //     if (accessToken !== null) {
    //         // @1) Fetching Syllabus
    //         axios
    //             .get(`${readAPI.Syllabus}/${courseCode}`, {
    //                 headers: {
    //                     "x-api-key": accessToken,
    //                 },
    //             })
    //             .then((res) => {
    //                 var data = res.data;
    //                 if (data && data.obj && data.obj.course) {
    //                     // Access properties only if they exist
    //                     data.obj.course.courseTypeName =
    //                         data.obj.course.courseType?.courseTypeName || 'Default Value';
    //                     setCourseData(data.obj.course);
    //                     setCourseOBjectives(data.obj.courseObjectives);
    //                     setCourseClos(data.obj.courseClos);
    //                     setCourseTopics(data.obj.courseTopics);
    //                     setCloSoMapping(data.obj.cloSoMapping);
    //                     setBookData(data.obj.courseBooks);
    //                     console.log('res', data);
    //                     if (data.code === "200.200") {
    //                         setResponseData(data);
    //                         setShowMessage(false);
    //                     }
    //                 } else {
    //                     setShowMessage(true);
    //                     setShowErrorMessage(data.message)
    //                     console.error("Invalid response data structure:", data.message);
    //                 }
    //             })
    //             .catch((err) => {
    //                 console.error("API request failed:", err);
    //             });
    //     }
    // }
    let accessToken: any = useSelector((state: RootState) => state.auth.accessToken);

    const showBasicSyllabus = () => {
        setIsLoadingSpinnerBasic(true);
        setResponseData([])
        setSelectedOption("basic")
        setShowErrorMessageForAbet("");
        setShowErrorMessageForNCAAA("");
        if (courseCode === "") {
            setCourseCodeError(true);
            setCourseCodeErrorMessage("Please enter course code");
            setShowAnchorEl(null);
        } else {
            setShowAnchorEl(null);
            // gettingSyllabusData();
            // let accessToken: any = Cookies.get("accessToken");
            if (accessToken !== null) {
                setAssessmentType(EqaCoreService.getAllAssessmentType())
                console.log(assessmentType)
                // @1) Fetching Syllabus
                axios
                    .get(`${readAPI.Syllabus}/${courseCode}`, {
                        headers: {
                            "x-api-key": accessToken,
                        },
                    })
                    .then((res) => {
                        var data = res.data;
                        setIsLoadingSpinnerBasic(false);
                        if (data && data.obj && data.obj.course) {
                            // Access properties only if they exist
                            data.obj.course.courseTypeName =
                                data.obj.course.courseType?.courseTypeName || 'Default Value';
                            setCourseData(data.obj.course);
                            setCourseOBjectives(data.obj.courseObjectives);
                            setCourseClos(data.obj.courseClos);
                            setCourseTopics(data.obj.courseTopics);
                            setCloSoMapping(data.obj.cloSoMapping);
                            setBookData(data.obj.courseBooks);
                            setAssessmentMethods(data.obj.assessmentMethods);
                            if (data.code === "200.200") {
                                setResponseData(data);
                                setShowMessage(false);
                            }
                        } else {
                            setShowMessage(true);
                            setShowErrorMessage(data.message)
                            console.error("Invalid response data structure:", data.message);
                        }
                    })
                    .catch((err) => {
                        console.error("API request failed:", err);
                    });
            }
        }

    }
    const showNcaaaSyllabus = () => {
        setResponseData([])
        setSelectedOption("ncaaa");
        setShowErrorMessage("");
        setShowErrorMessageForAbet("");
        if (courseCode === "") {
            setCourseCodeError(true);
            setCourseCodeErrorMessage("Please enter course code");
            setShowAnchorEl(null);
        } else {
            setShowAnchorEl(null);
            // gettingSyllabusData();
            // let accessToken: any = Cookies.get("accessToken");
            if (accessToken !== null) {
                // @1) Fetching Syllabus
                axios
                    .get(`${readAPI.Syllabus}/${courseCode}`, {
                        headers: {
                            "x-api-key": accessToken,
                        },
                    })
                    .then((res) => {
                        var data = res.data;
                        if (data && data.obj && data.obj.course) {
                            // Access properties only if they exist
                            data.obj.course.courseTypeName =
                                data.obj.course.courseType?.courseTypeName || 'Default Value';
                            setCourseData(data.obj.course);
                            setCourseOBjectives(data.obj.courseObjectives);
                            setCourseClos(data.obj.courseClos);
                            setCourseTopics(data.obj.courseTopics);
                            setCloSoMapping(data.obj.cloSoMapping);
                            setBookData(data.obj.courseBooks);
                            setAssessmentMethods(data.obj.assessmentMethods);
                            if (data.code === "200.200") {
                                setResponseData(data);
                                setShowMessage(false);
                            }
                        } else {
                            setShowMessage(true);
                            setShowErrorMessageForNCAAA(data.message)
                            console.error("Invalid response data structure:", data.message);
                        }
                    })
                    .catch((err) => {
                        console.error("API request failed:", err);
                    });
            }
        }
    }
    const showAbetSyllabus = () => {
        setResponseData([])
        setSelectedOption("abet");
        setShowErrorMessage("");
        setShowErrorMessageForNCAAA("");
        if (courseCode === "") {
            setCourseCodeError(true);
            setCourseCodeErrorMessage("Please enter course code");
            setShowAnchorEl(null);
        } else {
            setShowAnchorEl(null);
            // gettingSyllabusData()
            // let accessToken: any = Cookies.get("accessToken");
            if (accessToken !== null) {
                // @1) Fetching Syllabus
                axios
                    .get(`${readAPI.Syllabus}/${courseCode}`, {
                        headers: {
                            "x-api-key": accessToken,
                        },
                    })
                    .then((res) => {
                        var data = res.data;
                        if (data && data.obj && data.obj.course) {
                            // Access properties only if they exist
                            data.obj.course.courseTypeName =
                                data.obj.course.courseType?.courseTypeName || 'Default Value';
                            setCourseData(data.obj.course);
                            setCourseOBjectives(data.obj.courseObjectives);
                            setCourseClos(data.obj.courseClos);
                            setCourseTopics(data.obj.courseTopics);
                            setCloSoMapping(data.obj.cloSoMapping);
                            setBookData(data.obj.courseBooks);
                            setAssessmentMethods(data.obj.assessmentMethods);
                            if (data.code === "200.200") {
                                setResponseData(data);
                                setShowMessage(false);
                            }
                        } else {
                            setShowMessage(true);
                            setShowErrorMessageForAbet(data.message)
                            console.error("Invalid response data structure:", data.message);
                        }
                    })
                    .catch((err) => {
                        console.error("API request failed:", err);
                    });
            }
        }
    }


    const downloadBasicSyllabus = () => {
        if (courseCode === "") {
            setCourseCodeError(true);
            setCourseCodeErrorMessage("Please enter course code");
            setDownloadAnchorEl(null);
        }
        else {
            console.log("downloading syllabus in basic format.....");
            const pdf = new jsPDF('p', 'mm', 'a4');
            const content = document.getElementById('basic');

            if (content) {
                // html2canvas(content, { scale: 2 }).then(canvas => {
                //     const imgData = canvas.toDataURL('image/png');
                //     pdf.addImage(imgData, 'PNG', 10, 10, 190, 277); // Adjust dimensions as needed
                //     pdf.save('syllabus.pdf');
                // });

                html2canvas(content, { scale: 2 }).then(canvas => {
                    const imgData = canvas.toDataURL('image/png');
    
                    // Page dimensions for A4 size in mm
                    const pageWidth = 210;  // A4 width in mm
                    const pageHeight = 297; // A4 height in mm
    
                    // Initial coordinates to position the image
                    let imgX = 10;
                    let imgY = 10;
                    
                    // Image dimensions to scale the content properly
                    const imgWidth = 190;  // Width of the image (to fit within A4 width)
                    const imgHeight = 277; // Height of the image (to fit within A4 height)
    
                    // Add the first page with the content
                    pdf.addImage(imgData, 'PNG', imgX, imgY, imgWidth, imgHeight);
    
                    // Check the total height of the content
                    const contentHeight = canvas.height;
    
                    // If the content is too tall for one page, we need to split it across multiple pages
                    if (contentHeight > pageHeight) {
                        // Calculate how many pages we need based on content height
                        const numberOfPages = Math.ceil(contentHeight / pageHeight);
                        
                        for (let i = 1; i < numberOfPages; i++) {
                            // For each new page, adjust the starting Y position
                            pdf.addPage();
                            pdf.addImage(imgData, 'PNG', imgX, -i * pageHeight + 10, imgWidth, imgHeight);
                        }
                    }
    
                    // Save the generated PDF
                    pdf.save('syllabus.pdf');
                });
            }


           

            setDownloadAnchorEl(null);
            // const pdf = new jsPDF('p', 'mm', 'a4');
            // const headerImage = new Image();
            // headerImage.src = 'https://upload.wikimedia.org/wikipedia/commons/b/b6/Image_created_with_a_mobile_phone.png'

            // const headerTitle = 'College Of Computer and Information Technology';
            // headerImage.onload = () => {
            //     console.log('adskfasdf')
            //     pdf.addImage(headerImage, 'JPEG', 10, 10, 30, 30);
            //     pdf.text(headerTitle, 60, 30);
            //     const content = document.getElementById('basic');

            //     if (content) {
            //         html2canvas(content, { scale: 2 }).then(canvas => {
            //             const imgData = canvas.toDataURL('image/png');
            //             pdf.addImage(imgData, 'PNG', 10, 60, 190, 277); // Adjust dimensions and positions as needed
            //             pdf.save('syllabus.pdf');
            //         });
            //     }
            // };
        }
    }

    const downloadNcaaSyllabus = () => {
        if (courseCode === "") {
            setCourseCodeError(true);
            setCourseCodeErrorMessage("Please enter course code");
            setDownloadAnchorEl(null);
        }
        else {
            setDownloadAnchorEl(null);
        }
    }
    const downloadAbetSyllabus = () => {
        if (courseCode === "") {
            setCourseCodeError(true);
            setCourseCodeErrorMessage("Please enter course code");
            setDownloadAnchorEl(null);
        }
        else {
            const pdf = new jsPDF('p', 'mm', 'a4');
            const content = document.getElementById('basic');

            if (content) {
                html2canvas(content, { scale: 2 }).then(canvas => {
                    const imgData = canvas.toDataURL('image/png');
                    pdf.addImage(imgData, 'PNG', 10, 10, 190, 277); // Adjust dimensions as needed
                    pdf.save('syllabus.pdf');
                });
            }

            setDownloadAnchorEl(null);
            setDownloadAnchorEl(null);
        }
    }

    // const submitForm = () => {
    //     let accessToken: any = Cookies.get("accessToken");
    //     if (accessToken !== null) {
    //         // @1) Fetching Syllabus
    //         axios.get(`${readAPI.Syllabus}/${courseCode}`, {
    //             headers: {
    //                 "x-api-key": accessToken
    //             }
    //         })
    //             .then((res) => {
    //                 var data = res.data;
    //                 data.obj.course.courseTypeName = res.data.obj.course.courseType.courseTypeName;
    //                 setCourseData(data.obj.course);
    //                 setCourseOBjectives(res.data.obj.courseObjectives);
    //                 setCourseClos(res.data.obj.courseClos);
    //                 setCourseTopics(res.data.obj.courseTopics);
    //                 setCloSoMapping(res.data.obj.cloSoMapping);
    //                 setBookData(res.data.obj.courseBooks);
    //             })
    //             .catch((err) => {
    //                 console.log(err);
    //             });
    //     }

    // }





    const [windowDimensions, setWindowDimensions] = useState({
        width: window.innerWidth,
        height: window.innerHeight
    });

    // wwindow size
    const [windowSize, setWindowSize] = useState([
        window.innerWidth,
        window.innerHeight,
    ]);

    useEffect(() => {
        function handleResize() {
            setWindowDimensions({
                width: window.innerWidth,
                height: window.innerHeight
            });
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);


    // let accessToken: any = Cookies.get("accessToken");

    useEffect(() => {
        if (accessToken === undefined || accessToken === null) {
            accessToken = null;
        }


        if (accessToken !== null) {
            // @1) Fetching courses
            axios.get(readAPI.Courses, {
                headers: {
                    "x-api-key": accessToken
                }
            })
                .then((res) => {
                    setCourseCodeList(res.data.obj);
                })
                .catch((err) => {
                    console.log(err);
                });
            }

        }, []);

    return (
        <>

            <Box sx={{
                // border: "1px solid red",
                padding:
                    // Categorize according to small, medium, large screen
                    (windowSize[0] < 991) ? (2) : (windowSize[0] < 1200) ? (3) : (4),
                boxShadow: "rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;"
            }}>

                <Box sx={{
                    // border: "1px solid red",
                    display: "flex",
                    marginBottom: 2,
                    alignItems: (currentLang === "ar") ? ("flex-end") : ("flex-start"),
                    flexDirection: (currentLang === "ar") ? ("row-reverse") : ("row")
                }}>
                    <Box sx={{
                        boxShadow: "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;",
                        width: 60,
                        height: 60,
                        borderRadius: 1.25,
                        backgroundColor: "#fffefe",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}>

                        <FaBook size={27} style={{ marginTop: "3px" }} color="#4f747a" />
                    </Box>
                    <Box sx={{ ml: 3 }}>
                        <Typography variant="h5" sx={{
                            // color: "#312a2c",
                            color: "#3c6766",
                            fontWeight: 500,
                            marginTop: (windowSize[0] < 600) ? (0) : (0.5),
                            display: "flex",
                            flexDirection: (currentLang === "ar") ? ("row-reverse") : ("row")
                        }}>
                            {t('Home.Header.Modals.Syllabus.title')}
                        </Typography>
                        <Typography variant="body1" sx={{
                            // color: "#4f747a" 
                            // color: "#C0C0C0"
                            color: "#696969",
                            fontWeight: 300
                        }}>
                            {t('Home.Header.Modals.Syllabus.subTitle')}
                        </Typography>
                    </Box>
                </Box>

                <Box sx={{ flexGrow: 1, mt: 5 }}>
                    <Grid container spacing={
                        // Categorize according to small, medium, large screen
                        (windowSize[0] < 576) ? (0) : ((windowSize[0] < 768) ? (1) : ((windowSize[0] < 992) ? (2) : (3)))
                    }
                        dir={(currentLang === "ar") ? ('rtl') : ('ltr')}
                    >

                        <Grid item xl={6}
                            lg={6}
                            md={6}
                            sm={12}
                            xs={12}
                            sx={{marginTop: 2}}>

                            <Autocomplete
                                id="courseCodeComplete"
                                options={courseCodeListOptions}
                                getOptionLabel={(option: any) => option.label}
                                value={courseCodeListOptions?.find((option: any) => option.value === courseCode) || null}
                                onChange={handleCourseCodeChange}
                                renderInput={(params: any) => (
                                    <TextField
                                        {...params}
                                        label={`${t('Home.Header.Modals.Topics.policy.Inputs.courseCode.label')}`}
                                        placeholder={`${t('Home.Header.Modals.Topics.policy.Inputs.courseCode.placeholder')}`}
                                        variant="standard"
                                        error={courseCodeError}
                                        helperText={courseCodeErrorMessage}
                                        dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                    />
                                )}
                            />

                            {/* <TextField
                                id="courseCodeTextField"
                                label={t('Home.Header.Modals.Syllabus.policy.Inputs.courseCode.label')}
                                placeholder={`${t('Home.Header.Modals.Syllabus.policy.Inputs.courseCode.placeholder')}`}
                                variant="standard"
                                margin="normal"
                                fullWidth
                                error={courseCodeError}
                                helperText={courseCodeErrorMessage}
                                value={courseCode}
                                dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                onChange={(e) => {
                                    if (courseCodeError) {
                                        setCourseCodeError(false);
                                    }
                                    setCourseCode(e.target.value);
                                }}
                            /> */}
                        </Grid>
                        <Grid item
                            xl={6}
                            lg={6}
                            md={6}
                            sm={12}
                            xs={12}>
                            {/* button */}

                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: (currentLang === "ar") ? ('row-reverse') : ('row')
                                }}
                                dir={(currentLang === "ar") ? ('rtl') : ('ltr')}
                            >

                                <Button
                                    id="demo-customized-button"
                                    aria-controls={showOpen ? 'demo-customized-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={showOpen ? 'true' : undefined}
                                    variant="contained"
                                    disableElevation
                                    onClick={handleShowClick}
                                    endIcon={<KeyboardArrowDownIcon />}
                                    sx={{
                                        backgroundColor: "#d5b254",
                                        // textTransform: "none",
                                        fontWeight: "bold",
                                        ml: 1,
                                        height: 40,
                                        mt:
                                        // Give margins based on screen size
                                        {
                                            xs: 3, // theme.breakpoints.up('xs')
                                            sm: 2, // theme.breakpoints.up('sm')
                                            md: 2, // theme.breakpoints.up('md')
                                            lg: 3, // theme.breakpoints.up('lg')
                                            xl: 3, // theme.breakpoints.up('xl')
                                        },
                                        display: "flex",
                                        "&:hover": {
                                            backgroundColor: "#4f747ac3",
                                        },
                                    }}
                                >
                                    <Typography
                                        style={{ display: "block" }}
                                        dir={(currentLang === "ar") ? ('rtl') : ('ltr')}
                                    >
                                        {t('Home.Header.Modals.Syllabus.policy.Inputs.show.label')}
                                    </Typography>
                                </Button>
                                <StyledMenu
                                    id="demo-customized-menu"
                                    MenuListProps={{
                                        'aria-labelledby': 'demo-customized-button',
                                    }}
                                    anchorEl={showAnchorEl}
                                    open={showOpen}
                                    onClose={handleClose}
                                    sx={{ paddingTop: '0px' }}
                                >
                                    <MenuItem onClick={showBasicSyllabus} disableRipple sx={{
                                        backgroundColor: "#3c6766", color: "#fff", pt: 1, display: "flex",
                                        "&:hover": {
                                            backgroundColor: "#4f747ac3",
                                        },
                                    }}>
                                        <img src={basicImage} style={{
                                            marginRight: "10px",
                                            backgroundColor: "#fff",
                                            borderRadius: "50%"
                                        }}
                                            width={33}
                                            height={33}
                                            alt="avatar"
                                            loading="lazy" />
                                        Basic
                                    </MenuItem>
                                    <MenuItem onClick={showNcaaaSyllabus} disableRipple sx={{
                                        backgroundColor: "#3c6766", color: "#fff", display: "flex",
                                        "&:hover": {
                                            backgroundColor: "#4f747ac3",
                                        },
                                    }}>
                                        <img src={ncaaaImage} style={{
                                            marginRight: "10px",
                                            backgroundColor: "#fff",
                                            borderRadius: "50%"
                                        }}
                                            width={33}
                                            height={33}
                                            alt="avatar"
                                            loading="lazy" />
                                        NCAAA
                                    </MenuItem>
                                    <MenuItem onClick={showAbetSyllabus} disableRipple sx={{
                                        backgroundColor: "#3c6766", color: "#fff", display: "flex",
                                        "&:hover": {
                                            backgroundColor: "#4f747ac3",
                                        },
                                    }}>
                                        <img src={abetImage} style={{
                                            marginRight: "10px",
                                            backgroundColor: "#fff",
                                            borderRadius: "50%"
                                        }}
                                            width={33}
                                            height={33}
                                            alt="avatar"
                                            loading="lazy" />
                                        ABET
                                    </MenuItem>
                                </StyledMenu>

                                {/* <Button
                                    dir={(currentLang === "ar") ? ('rtl') : ('ltr')}
                                    variant="contained"
                                    sx={{
                                        backgroundColor: "#286e0a",
                                        // textTransform: "none",
                                        fontWeight: "bold",
                                        ml: 1,
                                        height: 40,
                                        mt:
                                        // Give margins based on screen size
                                        {
                                            xs: 3, // theme.breakpoints.up('xs')
                                            sm: 2, // theme.breakpoints.up('sm')
                                            md: 2, // theme.breakpoints.up('md')
                                            lg: 3, // theme.breakpoints.up('lg')
                                            xl: 3, // theme.breakpoints.up('xl')
                                        },
                                        display: "flex",
                                        "&:hover": {
                                            backgroundColor: "#d5b254",
                                        },
                                    }}
                                    fullWidth={(
                                        windowSize[0] < 600
                                    ) ? true : false}
                                    startIcon={
                                        (currentLang === "ar") ? (
                                            null
                                        ) : (
                                            <Download />
                                        )
                                    }
                                    endIcon={
                                        (currentLang === "ar") ? (
                                            <Download />
                                        ) : (
                                            null
                                        )
                                    }
                                    onClick={downloadBasicSyllabus}
                                >
                                    <Typography
                                        style={{ display: "block" }}
                                        dir={(currentLang === "ar") ? ('rtl') : ('ltr')}
                                    >
                                        {t('Home.Header.Modals.Syllabus.policy.Inputs.download.label')}
                                    </Typography>
                                </Button> */}


                                {/* menu item button */}

                                {selectedOption ? (
                                    <>
                                        <Button
                                            id="demo-customized-button"
                                            aria-controls={downloadOpen ? 'demo-customized-menu' : undefined}
                                            aria-haspopup="true"
                                            aria-expanded={downloadOpen ? 'true' : undefined}
                                            variant="contained"
                                            disableElevation
                                            onClick={handleDownloadClick}
                                            endIcon={<KeyboardArrowDownIcon />}
                                            sx={{
                                                backgroundColor: "#4f747ac3",
                                                // textTransform: "none",
                                                fontWeight: "bold",
                                                ml: 1,
                                                height: 40,
                                                mt:
                                                // Give margins based on screen size
                                                {
                                                    xs: 3, // theme.breakpoints.up('xs')
                                                    sm: 2, // theme.breakpoints.up('sm')
                                                    md: 2, // theme.breakpoints.up('md')
                                                    lg: 3, // theme.breakpoints.up('lg')
                                                    xl: 3, // theme.breakpoints.up('xl')
                                                },
                                                display: "flex",
                                                "&:hover": {
                                                    backgroundColor: "#4f747ac3",
                                                },
                                            }}
                                        >
                                            <Typography
                                                style={{ display: "block" }}
                                                dir={(currentLang === "ar") ? ('rtl') : ('ltr')}
                                            >
                                                {t('Home.Header.Modals.Syllabus.policy.Inputs.download.label')}
                                            </Typography>
                                        </Button>
                                        <StyledMenu
                                            id="demo-customized-menu"
                                            MenuListProps={{
                                                'aria-labelledby': 'demo-customized-button',
                                            }}
                                            anchorEl={downloadAnchorEl}
                                            open={downloadOpen}
                                            onClose={handleClose}
                                            sx={{ paddingTop: '0px' }}
                                        >
                                            <MenuItem onClick={downloadBasicSyllabus} disableRipple sx={{
                                                backgroundColor: "#3c6766", color: "#fff", pt: 1, display: "flex",
                                                "&:hover": {
                                                    backgroundColor: "#4f747ac3",
                                                },
                                            }}>
                                                <img src={basicImage} style={{
                                                    marginRight: "10px",
                                                    backgroundColor: "#fff",
                                                    borderRadius: "50%"
                                                }}
                                                    width={33}
                                                    height={33}
                                                    alt="avatar"
                                                    loading="lazy" />
                                                Basic
                                            </MenuItem>
                                            <MenuItem onClick={downloadNcaaSyllabus} disableRipple sx={{
                                                backgroundColor: "#3c6766", color: "#fff", display: "flex",
                                                "&:hover": {
                                                    backgroundColor: "#4f747ac3",
                                                },
                                            }}>
                                                <img src={abetImage} style={{
                                                    marginRight: "10px",
                                                    backgroundColor: "#fff",
                                                    borderRadius: "50%"
                                                }}
                                                    width={33}
                                                    height={33}
                                                    alt="avatar"
                                                    loading="lazy" />
                                                NCAAA
                                            </MenuItem>
                                            <MenuItem onClick={downloadAbetSyllabus} disableRipple sx={{
                                                backgroundColor: "#3c6766", color: "#fff", display: "flex",
                                                "&:hover": {
                                                    backgroundColor: "#4f747ac3",
                                                },
                                            }}>
                                                <img src={ncaaaImage} style={{
                                                    marginRight: "10px",
                                                    backgroundColor: "#fff",
                                                    borderRadius: "50%"
                                                }}
                                                    width={33}
                                                    height={33}
                                                    alt="avatar"
                                                    loading="lazy" />
                                                ABET
                                            </MenuItem>
                                        </StyledMenu>
                                    </>
                                ) : (null)}

                            </Box>
                        </Grid>

                    </Grid>
                </Box>

                <Box sx={{ flexGrow: 1, mt: 5 }}>

                    {responseData.length !== 0 && selectedOption === 'basic' ? (
                        <>
                            <StandardSyllabus 
                                currentLang={currentLang} 
                                windowSize={windowSize} 
                                courseData={courseData} 
                                cloSoMapping={cloSoMapping} 
                                courseObjective={courseObjectives}
                                courseCLO={courseClos} 
                                assessmentMethod={assessmentMethods}
                                courseTopic={courseTopics} 
                                bookData={bookData} />
                            {/* <p>{showErrorMessage}</p> */}
                        </>
                    ) : null}

                    {responseData.length !== 0 && selectedOption === 'abet' ? (
                        <AbetSyllabusFormat
                            currentLang={currentLang} 
                            windowSize={windowSize} 
                            courseData={courseData} 
                            cloSoMapping={cloSoMapping} 
                            courseObjective={courseObjectives}
                            courseCLO={courseClos} 
                            assessmentMethod={assessmentMethods}
                            courseTopic={courseTopics} 
                            bookData={bookData} />
                    ) : showMessage ? (
                        <p>{showErrorMessageForAbet}</p>
                    ) : null}

                    {responseData.length !== 0 && selectedOption === 'ncaaa' ? (
                        <div>
                            <NCAAASyllabusFormat
                                currentLang={currentLang} 
                                windowSize={windowSize} 
                                courseData={courseData} 
                                cloSoMapping={cloSoMapping} 
                                courseObjective={courseObjectives}
                                courseCLO={courseClos} 
                                assessmentMethod={assessmentMethods}
                                courseTopic={courseTopics} 
                                bookData={bookData} />
                        </div>
                    ) : showMessage ? (
                        <p>{showErrorMessageForNCAAA}</p>
                    ) : null}


                </Box>
            </Box>










            <SnackBar
                isOpen={snackBarHandler.open}
                message={snackBarHandler.message}
                severity={snackBarHandler.severity}
                setIsOpen={
                    // Only pass the setIsOpen function to the SnackBar component
                    // and not the whole state object
                    (isOpen: boolean) => setSnackBarHandler({ ...snackBarHandler, open: isOpen })
                }
            />

            <br /><br /> <br />



        </>
    )
}
export default Syllabus;