import Cookies from "js-cookie";
import { BASE_URL } from "../../constants";
import { useDispatch } from "react-redux";
import { clearPicture } from "../../redux/action/authActions";


async function logoutCore(url = "", apiKey) {
  
  let endPoint = `${BASE_URL}/IDENTITY-SERVICE/user/logout`;

  return fetch(endPoint, { method: "GET", headers: { "x-api-key": apiKey } })
    .then((response) => response.json())
    .then((res) => {
      console.log("I am in side logoutCore...");
      console.log("shabbir .. LocalStorage has been cleared after logged out...");
      localStorage.clear();
      return res;
      //if (res.status === "OK") {
        // Cookies.remove("accessToken");
        
        //localStorage.clear();
        // localStorage.removeItem("sidebarAppsListArray");
        // localStorage.removeItem("userPic")
        // localStorage.removeItem("user");
        // console.log("Logout Success");
        //return res;
      //}
      //throw new Error("Unexpected response from the API");
    })
    .catch((err) => {
      // console.error("Error in logoutCore: ", err.message);
      throw err;
    });
}
export { logoutCore };


