import {
    Box,
    Typography,
    Button,
    Grid,
    TextField,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Autocomplete
} from "@mui/material";
import { useTranslation } from "react-i18next";
import styles from "./style.module.css";
import {ICourse, ICourseObjective, ICourseCLO, IAssessmentMethod, ICourseTopic, ICourseBook, ICLOSOMapping} from '../../../model/EqaCore';
import React from 'react'
import CourseObjective from "../CourseObjective/AddCourseObjective";
import { CourseObjectiveSection } from "./CourseObjectiveSection";
import { CourseCLOSection } from "./CourseCLOSection";
import { AssessmentMethodSection } from "./AssessmentMethodSection";
import { CourseTopicSection } from "./CourseTopicSection";
import { CourseBookSection } from "./CourseBookSection";
import { CLOSOMappingSection } from "./CLOSOMappingSection";
import { CourseDetailSection } from "./CourseDetailSection";


interface AbetProps{
    currentLang: string,
    courseData: ICourse,
    windowSize: number[],
    courseObjective: ICourseObjective[],
    courseCLO: ICourseCLO[],
    assessmentMethod: IAssessmentMethod[],
    courseTopic: ICourseTopic[],
    bookData: ICourseBook[],
    cloSoMapping: ICLOSOMapping[],
}

export const AbetSyllabusFormat : React.FC<AbetProps>= ({currentLang, windowSize, courseData,courseObjective,courseCLO, assessmentMethod, courseTopic, bookData,cloSoMapping}) => {
    
  return (
    <div id="abet">
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ paddingRight: "-20px" }}>
                                <Box sx={{ flexGrow: 1, padding: 0 }}>
                                    <Grid container sx={{ alignItems: 'center'}} spacing={
                                        // Categorize according to small, medium, large screen
                                        (windowSize[0] < 576) ? (0) : ((windowSize[0] < 768) ? (1) : ((windowSize[0] < 992) ? (2) : (3)))
                                    } >

                                        {/* University Logo */}
                                        <Grid item xl={2} lg={2} md={2} sm={12} xs={12} sx={{ mt: -2 }}>
                                            <img
                                                // className={styles.universityLogo}
                                                width={120}
                                                height={120}
                                                src={require(`../../../../../assets/Images/universityLogo/taifuniversitylogo.jpeg`)}
                                                alt="University Logo"
                                            // style={{ width: '100%', height: 'auto', display: 'block'}}
                                            />
                                        </Grid>

                                        <Grid item xl={8} lg={8} md={10} sm={12} xs={12} sx={{ padding: 0 }}>
                                            <p className={styles.collegeNameHeading}>Taif University</p>
                                            <p className={styles.collegeNameHeading}>College Of Computer and Information Technology</p>

                                        </Grid>

                                         {/* ABET Logo */}
                                         <Grid item xl={2} lg={2} md={2} sm={12} xs={12} sx={{ mt: -2 }}>
                                            <img
                                                // className={styles.universityLogo}
                                                width={120}
                                                height={110}
                                                src={require(`../../../../../assets/Images/Syllabus/ABET.png`)}
                                                alt="ABET Logo"
                                            // style={{ width: '100%', height: 'auto', display: 'block'}}
                                            />
                                        </Grid>
                                    </Grid>
                                </Box>
                            </TableCell>
                        </TableRow>
                    </TableHead>

                </Table>
            </TableContainer>
            <TableContainer component={Paper} className={styles.tablecontainer}>
                <Table className={styles.customtable}>
                    <TableHead>
                        <TableRow>
                            <TableCell colSpan={6} className={styles.heading}><span>Course Specification</span></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell className={styles.label}><span>COURSE CODE</span></TableCell>
                            <TableCell>{courseData.courseCode}</TableCell>
                            <TableCell className={styles.label}><span>COURSE NAME</span></TableCell>
                            <TableCell colSpan={4}>{courseData.courseName}</TableCell>

                        </TableRow>


                        <TableRow>
                            <TableCell className={styles.label}><span>SEMESTER</span></TableCell>
                            <TableCell></TableCell>
                            <TableCell className={styles.label}><span>ACADEMIC YEAR</span></TableCell>
                            <TableCell>{courseData.academicYearOffered}</TableCell>
                            <TableCell className={styles.label}><span>CREDIT HOURS</span></TableCell>
                            <TableCell>{courseData.courseCreditHours}</TableCell>

                        </TableRow>
                        <TableRow>
                            <TableCell className={styles.label}><span>ENROLMENT</span></TableCell>
                            <TableCell></TableCell>
                            <TableCell className={styles.label}><span>WITHDRAWL</span></TableCell>
                            <TableCell></TableCell>
                            <TableCell className={styles.label}><span>COURSE COREQUISITE</span></TableCell>
                            <TableCell>{courseData.courseCoRequisite}</TableCell>
                        </TableRow>

                    </TableBody>
                </Table>
            </TableContainer>

            <Typography
                style={{ display: "block", fontWeight: "bold", marginTop: "20px", marginBottom: "20px" }}
                dir={(currentLang === "ar") ? ('rtl') : ('ltr')}
            >
            </Typography>

            <TableContainer component={Paper} className={styles.tablecontainer}>
                <Table className={styles.customtable}>
                    <TableBody>
                        <TableRow>
                            <TableCell className={styles.label}><span>INSTRUCTOR</span></TableCell>
                            <TableCell></TableCell>
                            <TableCell className={styles.label}><span>EMAIL</span></TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className={styles.label}><span>OFFICE HOURSE</span></TableCell>
                            <TableCell></TableCell>
                            <TableCell className={styles.label}><span>LOCATION</span></TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className={styles.label}><span>TEACHING ASSISTANS</span></TableCell>
                            <TableCell></TableCell>
                            <TableCell className={styles.label}><span>PHONE</span></TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className={styles.label}><span>COURSE CO-ORDINATOR</span></TableCell>
                            <TableCell colSpan={3}></TableCell>
                        </TableRow>

                    </TableBody>
                </Table>
            </TableContainer>


            <Typography
                style={{ display: "block", fontWeight: "bold", marginTop: "20px", marginBottom: "20px" }}
                dir={(currentLang === "ar") ? ('rtl') : ('ltr')}
            >
            </Typography>

            <TableContainer component={Paper} className={styles.tablecontainer}>
                <Table className={styles.customtable}>
                    <TableHead>
                        <TableRow>
                            <TableCell className={styles.label}><span>COURSE FORMAT</span></TableCell>
                            <TableCell className={styles.label}><span>LECTURE TIME/VENUE</span></TableCell>
                            <TableCell className={styles.label}><span>SECTION</span></TableCell>
                            <TableCell className={styles.label}><span>COURSE WEBSITE</span></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                        </TableRow>

                    </TableBody>
                </Table>
            </TableContainer>

            <Typography
                style={{ display: "block", fontWeight: "bold", marginTop: "20px", marginBottom: "20px" }}
                dir={(currentLang === "ar") ? ('rtl') : ('ltr')}
            >
            </Typography>

            <TableContainer component={Paper} className={styles.tablecontainer}>
                <Table className={styles.customtable}>
                    <TableHead>
                        <TableRow>
                            <TableCell className={styles.label}><span>DESCRIPTION</span></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell colSpan={3}>kkkkk</TableCell>
                        </TableRow>

                    </TableBody>
                </Table>
            </TableContainer>

            <CourseObjectiveSection currentLang={currentLang} courseObjective={courseObjective} />
            <CourseCLOSection currentLang={currentLang} courseCLO={courseCLO} />
            <CourseTopicSection currentLang={currentLang} courseTopic={courseTopic} />
            <AssessmentMethodSection currentLang={currentLang} assessmentMethod={assessmentMethod} />
            <CLOSOMappingSection currentLang={currentLang} cloSoMapping={cloSoMapping} />
            <CourseBookSection currentLang={currentLang} courseBook={bookData} />                  
            
            {/*POLICY */}
            <TableContainer component={Paper} className={styles.tablecontainer}>
                <Table className={styles.customtable}>
                    <TableHead>
                        <TableRow>
                            <TableCell colSpan={4} className={styles.heading}><span>COURSE POLICY</span></TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className={styles.label}><span>ID</span></TableCell>
                            <TableCell colSpan={3} className={styles.label}><span>COURSE POLICY DESCRIPTION</span></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {courseCLO && courseCLO?.map((item: any, index: any) => {
                            return (
                                <>
                                    <TableRow>
                                        <TableCell>{item.id}</TableCell>
                                        <TableCell colSpan={2}>{item.policy}</TableCell>
                                    </TableRow>
                                </>
                            )
                        })}

                    </TableBody>
                </Table>
            </TableContainer>
    </div>
  )
}