
import {
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Autocomplete
} from "@mui/material";
import styles from "./style.module.css";
import {ICourseBook} from '../../../model/EqaCore';
interface CourseBookSectionProps{
    currentLang: string,
    courseBook: ICourseBook[]
}

export const CourseBookSection:React.FC<CourseBookSectionProps>=({currentLang, courseBook})=>{
    return(
        <>
            <TableContainer component={Paper} className={styles.tablecontainer}>
                <Table className={styles.customtable}>
                    <TableHead>
                        <TableRow>
                            <TableCell colSpan={4} className={styles.heading}><span>BOOK DETAILS</span></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {courseBook && courseBook.map((item: any, index: any) => {
                            return (
                                <>
                                    <TableRow>
                                        <TableCell className={styles.label}><span>COURSE CODE</span></TableCell>
                                        <TableCell>{item.courseCode}</TableCell>
                                        <TableCell className={styles.label}><span>TEXT BOOK</span></TableCell>
                                        <TableCell>{item.textBook.name}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell className={styles.label}><span>REFERENCE BOOK</span></TableCell>
                                        <TableCell>{item.referenceBook.name}</TableCell>
                                        <TableCell className={styles.label}><span>OTHER REFERENCE BOOK</span></TableCell>
                                        <TableCell>{item.otherReferenceBook}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell className={styles.label}><span>ELECTRONIC MATERIALS</span></TableCell>
                                        <TableCell>{item.electronicMaterials}</TableCell>
                                        <TableCell className={styles.label}><span>OTHER LEARNING MATERIALS</span></TableCell>
                                        <TableCell>{item.otherLearningMaterials}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell className={styles.label}><span>REFERENCE MATERIALS</span></TableCell>
                                        <TableCell colSpan={3}>{item.referenceMaterials}</TableCell>
                                    </TableRow>
                                </>
                            )
                        })}

                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}