import {
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Autocomplete
} from "@mui/material";
import styles from "./style.module.css";
import {ICourseCLO} from '../../../model/EqaCore';

interface CourseCLOProps{
    currentLang: string,
    courseCLO: ICourseCLO[]
}


export const CourseCLOSection: React.FC<CourseCLOProps>= ({currentLang, courseCLO}) => {
    return (
        <>
            <TableContainer component={Paper} className={styles.tablecontainer}>
                <Table className={styles.customtable}>
                    <TableHead>
                        <TableRow>
                            <TableCell colSpan={4} className={styles.heading}><span>COURSE LEARNING OUTCOME</span></TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className={styles.label}><span>CLO</span></TableCell>
                            <TableCell colSpan={2} className={styles.label}><span>DESC</span></TableCell>
                            <TableCell className={styles.label}><span>CLO CATEGORY</span></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {courseCLO && courseCLO?.map((item: any, index: any) => {
                            return (
                                <>
                                    <TableRow>
                                        <TableCell>{item.cloId}</TableCell>
                                        <TableCell colSpan={2}>{item.description}</TableCell>
                                        <TableCell>{item.cloCategory}</TableCell>
                                    </TableRow>
                                </>
                            )
                        })}

                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}