
import {
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Autocomplete
} from "@mui/material";
import styles from "./style.module.css";
import {ICLOSOMapping} from '../../../model/EqaCore';
interface CLOSOMappingSectionProps{
    currentLang: string,
    cloSoMapping: ICLOSOMapping[]
}

export const CLOSOMappingSection: React.FC<CLOSOMappingSectionProps>=({currentLang,cloSoMapping})=>{
    return(
        <>
            <TableContainer component={Paper} className={styles.tablecontainer}>
                <Table className={styles.customtable}>
                    <TableHead>
                        <TableRow>
                            <TableCell colSpan={6} className={styles.heading}><span>CLOSO MAPPING</span></TableCell>
                        </TableRow>
                        <TableRow>
                            
                            <TableCell className={styles.label}><span>CLO ID</span></TableCell>
                            <TableCell colSpan={5} className={styles.label}><span>SO ID</span></TableCell>
                            {/* <TableCell colSpan={4} className={styles.label}><span>Description</span></TableCell> */}
                            {/* <TableCell className={styles.label}><span>SO VALUE</span></TableCell> */}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {cloSoMapping && cloSoMapping?.map((item: any, index: any) => {
                            return (
                                <>
                                    <TableRow>
                                        
                                        <TableCell>{item.cloId}</TableCell>
                                        <TableCell>{item.soId}</TableCell>
                                        {/* <TableCell colSpan={4}>{item.soDescription}</TableCell> */}
                                        {/* <TableCell>{item.soValue}</TableCell> */}
                                    </TableRow>
                                </>
                            )
                        })}

                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}