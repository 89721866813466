import {
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Autocomplete
} from "@mui/material";
import styles from "./style.module.css";
import {ICourseObjective} from '../../../model/EqaCore';

interface CObjectiveProps {
    currentLang: string
    courseObjective?: ICourseObjective[];  // Prop name must be 'courseObjective'
  }
export const CourseObjectiveSection : React.FC<CObjectiveProps>= ({currentLang, courseObjective}) => {

    return(
        <>
            {/* <Typography
                style={{ display: "block", fontWeight: "bold", marginTop: "20px", marginBottom: "20px" }}
                dir={(currentLang === "ar") ? ('rtl') : ('ltr')}
            > */}
                {/* COURSE OBJECTIVE */}
            {/* </Typography> */}
            <TableContainer component={Paper} className={styles.tablecontainer}>
                <Table className={styles.customtable}>
                    <TableHead>
                        <TableRow>
                            <TableCell colSpan={12} className={styles.heading}><span>COURSE OBJECTIVE</span></TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className={styles.label}><span>Id</span></TableCell>
                            <TableCell colSpan={10} className={styles.label}><span>DESCRIPTION</span></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {courseObjective && courseObjective?.map((item: any, index: any) => {
                            return (
                                <>
                                    <TableRow>
                                        <TableCell>{item.id}</TableCell>
                                        <TableCell colSpan={3}>{item.description}</TableCell>
                                    </TableRow>
                                </>
                            )
                        })}

                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}