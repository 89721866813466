// Action types for offered courses
export const FETCH_OFFERED_COURSES_REQUEST = 'FETCH_OFFERED_COURSES_REQUEST';
export const FETCH_OFFERED_COURSES_SUCCESS = 'FETCH_OFFERED_COURSES_SUCCESS';
export const FETCH_OFFERED_COURSES_FAILURE = 'FETCH_OFFERED_COURSES_FAILURE';
export const CLEAR_OFFERED_COURSE = 'CLEAR_OFFERED_COURSE';

// Actioin types for colleges
export const FETCH_COLLEGES_REQUEST = 'FETCH_COLLEGES_REQUEST';
export const FETCH_COLLEGES_SUCCESS = 'FETCH_COLLEGES_SUCCESS';
export const FETCH_COLLEGES_FAILURE = 'FETCH_COLLEGES_FAILURE';

