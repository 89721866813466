
import {
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Autocomplete
} from "@mui/material";
import styles from "./style.module.css";
import {ICourseTopic} from '../../../model/EqaCore';
interface CourseTopicSectionProps{
    currentLang: string,
    courseTopic: ICourseTopic[]
}
export const CourseTopicSection:React.FC<CourseTopicSectionProps>=({currentLang, courseTopic})=>{
    return(
        <>
            <TableContainer component={Paper} className={styles.tablecontainer}>
                <Table className={styles.customtable}>
                    <TableHead>
                        <TableRow>
                            <TableCell colSpan={6} className={styles.heading}><span>TOPICS</span></TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell colSpan={1} className={styles.label}><span>WEEKLY OUTLINE</span></TableCell>
                            <TableCell colSpan={4} className={styles.label}><span>TOPIC</span></TableCell>
                            <TableCell colSpan={1} className={styles.label}><span>CLO COVERED</span></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {courseTopic && courseTopic?.map((item: any, index: any) => {
                            return (
                                <>
                                    <TableRow>
                                        <TableCell>{item.weeksDelivered}</TableCell>
                                        <TableCell colSpan={4}>{item.topic}</TableCell>
                                        <TableCell>{item.closCovered}</TableCell>
                                    </TableRow>
                                </>
                            )
                        })}

                    </TableBody>
                </Table>
            </TableContainer>   
        </>
    )
}